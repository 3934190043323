<template>
  <div id="dashboard">
    <div class="row no-gutters">
      <app-title title="mdl.home.title" subtitle="mdl.home.subtitle" ></app-title>
    </div>
    <div class="row no-gutters">
      <div class="col-10 offset-1">
        <app-breadcrumb items="mdl.bread_home;/mdl;home"> </app-breadcrumb>
      </div>
    </div>
    <div class="col-10 offset-1 mt-3">
      <b-alert  variant="warning" id="errore_waiting" v-model="warn" ><strong>{{$t('mdl.consensi.referti.p0')}}</strong><br>
        <strong>{{$t('mdl.consensi.episodi.p1')}}</strong> {{$t('mdl.consensi.episodi.p2')}}<router-link to="/dettagliUtenza?tab=6" ><strong class="alert-warning"><u>{{$t('mdl.consensi.episodi.l0')}}</u></strong></router-link>
      </b-alert>

    </div>
    <div class="row no-gutters d-none d-md-block col-10 offset-1" style="overflow-x: scroll">
      <div class="o-mdl-list o-mdl-list--table mt-3 ">
        <div class="o-mdl-list__head " v-if="this.planning.length>0 && !this.loading ">
          <div class="o-mdl-list__innerb">
            <div class="o-mdl-list--title col-4">
              {{ $t("mdl.home.tab_0") }}
            </div>
            <div class="o-mdl-list--title col-3">
              {{ $t("mdl.home.tab_1") }}
            </div>

            <div class="o-mdl-list--title col-2">
              {{ $t("mdl.home.tab_3") }}
            </div>
            <div class="o-mdl-list--title col-3">
              {{ $t("mdl.home.tab_4") }}
            </div>
          </div>

          <div class="o-mdl-list__actionb">
            <div class="o-mdl-list--download">
             &nbsp;
            </div>

          </div>
        </div>

        <div class="row col-12 text-center" v-if=" this.planning.length==0 && !this.loading">
          <h3 class="col-12">{{$t('mdl.dispo.no_episodio')}}</h3>
        </div>
        <div class="m-mdl-card m-mdl-card--line " v-for="plan in this.planning" v-bind:key="plan.key">

          <div
              class="m-mdl-card__container align-items-center"
              v-bind:class="{ 'm-mdl-card__container--active': plan.status=='PASSED' || plan.status=='LATE' }"
          >

            <div class="m-mdl-card__innerb ">
              <div class="m-mdl-card__text--name col-4">
               <!-- <p v-for="prest in plan.prestazioni" v-bind:key="prest">
                  {{prest}}
                </p>-->
                <AppPrestazioni :items="plan.prestazioni" mode="p"></AppPrestazioni>
              </div>

              <div class="m-mdl-card__text--name col-3" >
                <label :class="{'m-mdl-card__red':plan.status=='IDLE'||plan.status=='LATE','m-mdl-card__green':plan.status=='PASSED'||plan.status=='TODAY'}">
                {{$t("mdl.home.stato."+plan.status)}}
                  <span class="m-mdl-card__red " v-if="plan.status==='IDLE'||plan.status==='LATE'">
                    <br> {{ $t('mdl.dispo.entro') }}: {{$t('mdl.dispo.month.M'+plan.episodio.mese)}} {{plan.episodio.anno}}
                  </span>
                </label>


              </div>

              <div class="m-mdl-card__text--name col-2" v-if="plan.reservation!=null">
                {{ plan.reservation.data + " " +plan.reservation.ora }}
              </div>
              <div class="m-mdl-card__text--name col-3" v-if="plan.reservation!=null">
                {{ plan.reservation.dove}}
              </div>
              <div class="m-mdl-card__text--name col-2" v-if="plan.reservation==null">
               &nbsp;
              </div>
              <div class="m-mdl-card__text--name col-3" v-if="plan.reservation==null">
                &nbsp;
              </div>
            </div>

            <div  class="m-mdl-card__actionb text-right ">
              <b-button v-if="plan.status=='IDLE' || plan.status=='LATE'" variant="primary"  size="md" class="m-mdl-card__action-button" @click="prenota(plan)">{{ $t("mdl.home.action.reserve") }}
              </b-button>
              <b-button v-if="plan.status=='RESERVED' " variant="primary" class="m-mdl-card__red-bg m-mdl-card__action-button" @click="annulla(plan)" size="md">{{ $t("mdl.home.action.cancel") }}
              </b-button>

            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="row d-flex d-md-none">
      <div class="col-10 offset-1">
      <div class="row no-gutters" v-if=" this.planning.length==0 && !this.loading" >
        <div class="col-12 offset-1">
          Nessun episodio
        </div>
      </div>
      <div class="m-mdl-card m-mdl-card--line mb-5 " v-for="plan in this.planning" v-bind:key="plan.key">

         <div class="col-12 pl-0 pr-0 ">
          <div class=""><strong> {{ $t("mdl.home.tab_0") }}:</strong></div>

           <AppPrestazioni :items="plan.prestazioni" mode="ul-sm"></AppPrestazioni>
          <div class=""> {{ $t("mdl.home.tab_1") }}:</div>
          <label :class="{'m-mdl-card__red':plan.status=='IDLE'||plan.status=='LATE','m-mdl-card__green':plan.status=='PASSED'}">
            <strong>{{$t("mdl.home.stato."+plan.status)}}</strong>
          </label>
          <div v-if="plan.episodio!=null&&plan.episodio.nome!=null" class=""> {{ $t("mdl.home.tab_2") }}: </div>
          <label v-if="plan.episodio!=null&&plan.episodio.nome!=null"><strong>{{plan.episodio==null?"&nbsp;":plan.episodio.nome}}</strong></label>
          <div v-if="plan.reservation!=null">
            <div class=""> {{ $t("mdl.home.tab_3") }}:</div>
            <label >
              <strong>{{plan.reservation.data + " " +plan.reservation.ora }}</strong>
            </label>
            <div class=""> {{ $t("mdl.home.tab_4") }}:</div>
            <label >
              <strong>{{plan.reservation.dove }}</strong>
            </label>
          </div>
          <div  class="col-12">
            <b-button v-if="plan.status=='IDLE' || plan.status=='LATE'" variant="primary" class="col-12"  size="md" @click="prenota(plan)">{{ $t("mdl.home.action.reserve") }}
            </b-button>
            <b-button v-if="plan.status=='RESERVED' " variant="primary" class="m-mdl-card__red-bg col-12" @click="annulla(plan)" size="md">{{ $t("mdl.home.action.cancel") }}
            </b-button>

          </div>
      </div>
      </div>

      </div>
    </div>

    <b-modal ref="noreferti-dialog"
             id="noreferti"
             centered
             modal-class="o-modal"
             content-class="o-modal__content"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="true"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >

      <p class="o-modal__body--title mt-5">
        <strong>Account non abilitato </strong>
      </p>
      <div class="mt-5">
        <p>Abilitare la visulizzazione dei referti:  </p>
      </div>
      <div class="row col-12 mt-2">
        <b-button variant="outline-primary" size="md" class="text-uppercase mt-3 col-5 " @click="annullaNoreferti()"
        >{{ $t('mdl.home.delete.annulla') }} </b-button>
      </div>
    </b-modal>
    <b-modal ref="delete-dialog"
             id="delete"
             centered
             modal-class="o-modal"
             content-class="o-modal__content"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="true"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >

      <p class="o-modal__body--title mt-5">
        <strong>Cancella prenotazione </strong>
      </p>

      <div v-if="selected!=null && selected.reservation!=null" class="mt-5">
        <p>Sei sicuro di volere cancellare questa prenotazione:  </p>
        <AppPrestazioni :items="selected.prestazioni" mode="p-strong"></AppPrestazioni>

        <p >del giorno <strong>{{this.selected.reservation.data}}</strong> ore <strong>{{this.selected.reservation.ora}}</strong>?</p>
      </div>
      <div class="row col-12 mt-2">
        <b-button variant="danger" size="md" class="text-uppercase mt-3 d-block d-md-none col-12" @click="startCancel()"
        >{{ $t('mdl.home.delete.cancella') }} </b-button>
        <b-button variant="outline-primary" size="md" class="text-uppercase mt-3 col-md-5 col-12 " @click="annullaCancel()"
        >{{ $t('mdl.home.delete.annulla') }} </b-button>
        <b-button variant="danger" size="md" class="text-uppercase mt-3  col-5 offset-2 d-none d-md-block" @click="startCancel()"
        >{{ $t('mdl.home.delete.cancella') }} </b-button>
      </div>
    </b-modal>
    <b-modal ref="reserve-dialog"
             id="reserve"
             centered
             modal-class="o-modal"
             content-class="o-modal__content"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response pl-0 pr-0 "
             ok-title="ok-title"
             :cancel-disabled="true"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >
    <DlgMdlFiltro newquery="true" :plan="this.selected" @ondata="startRequest()" @onhide="annullaRequest()"></DlgMdlFiltro>
    </b-modal>
  </div>

</template>

<script>

import AppBreadcrumb from "@/components/molecules/AppBreadcrumb";
import AppTitle from "@/components/molecules/AppTitle";
import apiAxios from "@/libs/apiAxios";
import router from "@/router";
import DlgMdlFiltro from "@/components/molecules/DlgMdlFiltro";
import AppPrestazioni from "@/components/molecules/AppPrestazioni";
import md5 from "md5";
export default {
  name: "prenota",
  components: {AppPrestazioni, DlgMdlFiltro, AppBreadcrumb,AppTitle},


  props: {},
  data() {
    return {
      warn:true,
      userdata:null,
      selected:null,
      data_inizio:null,
      dialogErrorStr:null,
      interno:"I",
      planning:[],
      errCancel:null,
      optionsInternoEsterno: [
        { value: "I", text: "Interno" },
        { value: "E", text: "Esterno" },
      ],
      loading:true
    };
  },
  computed:{},
  created() {
    sessionStorage.removeItem('mdlrequest');
    if (!this.checkSessionConsensi())
    {
      router.push("/mdl");
      return;
    }
    this.loadData();
  },
  methods: {
    checkSessionConsensi() {

      let data=sessionStorage.getItem("mdl_consensi_key");
      if (data==null)
        return false;
      data=JSON.parse(data);
      if (data==null) {
        sessionStorage.removeItem("mdl_consensi_key")
        return false;
      }
      const hash_i= md5(data.stuff+data.ts+"S3Cr3tMdl");

      const date= new Date();

      if (date.getMilliseconds() > data.ts)
      {
        sessionStorage.removeItem("mdl_consensi_key");
        return false;
      }

      if (hash_i===data.hash)
      {
        return true;
      }
      else {
        sessionStorage.removeItem("mdl_consensi_key");
        return false;
      }
    },
    annullaNoreferti()
    {
      this.$refs["noreferti-dialog"].hide();
    },
    view(plan)
    {
      console.log(plan);
      let enabled=false;
      for(var s=0;s<this.userdata.services.length;s++)
      {
        if (this.userdata.services[s].name==="REFERTI")
        {
          enabled=this.userdata.services[s].enabled;
          break;
        }
      }

      if (!enabled)
      {
        this.$refs["noreferti-dialog"].show();
      }
      else {
        sessionStorage.removeItem('singolo');
        sessionStorage.removeItem('referti');

        apiAxios.get('/referti/enabled')
            .then(response => {
              if (response.data.IsEnabledResult)
              {
                router.push('/referti?mdl=true')
              }
              else {
                router.push('/confermaAccessoApp?service=fatref&goto=referti&mdl=true');
              }
            })
            .catch(error=>{
              console.log(error);
            });
      }

    },
    calcMinDate()
    {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let tomorrow=today;
      tomorrow.setDate(today.getDate() + 1);

      return tomorrow;

    },
    failReserveDialog(err)
    {
      this.dialogErrorStr=err;

    },
    successReserveDialog(query,result)
    {
      this.$refs["reserve-dialog"].hide();
      this.selected= null;

      const data={
        query:query,
        data:result
      }


      sessionStorage.setItem('mdlrequest',btoa(JSON.stringify(data)));
      router.push('/mdl/disponibilita');
    },
    annullaRequest() {
      this.$refs["reserve-dialog"].hide();
    },
    startRequest()
    {
      this.$router.push('/mdl/disponibilita')
    },
    annulla(plan){
      this.errCancel=null;
      this.selected=plan;
      this.$refs["delete-dialog"].show();
    },
    annullaCancel()
    {
      this.$refs["delete-dialog"].hide();
      this.selected=null;
    },
    errorCancel(err)
    {
      this.errCancel=err;
    },
    startCancel()
    {
      this.errCancel=null;
      const post_data={
        guid: this.selected.reservation.guid
      }
      apiAxios.post("mdl/delete",post_data).then(result =>{
        const data=result.data.MdlDeleteResult;
        if (data.success)
        {
          this.loadData();
          this.$refs["delete-dialog"].hide();
        }
        else {
          this.errCancel=data.error;
        }
      }).catch(error => {
        this.errCancel=error;
      });

    },
    prenota(plan){
      this.selected= plan;
      const d=this.calcMinDate();
      this.data_inizio= d.getFullYear()+ "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
          ("0" + d.getDate()).slice(-2)  ;

      this.$refs["reserve-dialog"].show();
    },
    getPrestazione(plan){
      let res="";
      for(var i=0;i<plan.prestazioni.length;i++)
      {
        if (i>0)
          res+="\n";
        res+=plan.prestazioni[i];
      }
      return res;
    },
    loadData(){
      this.loading=true;
      this.userdata=JSON.parse(sessionStorage.getItem("userdata"));
      apiAxios.get("mdl/plan")
          .then(result=>{
            this.loading=false;
            const data=result.data.MdlPlanResult;
            if (data.success)
            {
              this.planning=data.data;
            }
            else {
              if (!data.enrolled) {
                router.push("/mdl");
                return;
              }

            }
          })
      .catch(error=>{
        console.log(error);
        this.loading=false;
      })
    }
  }
};
</script>
